@import './../../../resources/styles/variables.less';

.sidebar-container {
  background-color: #f5f5f5;
  .menu-item {
    padding: 0 9px 2px 9px !important;
    margin: none !important;
  }
  @media @mobile {
    display: none;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;