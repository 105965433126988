@import '../../../resources/styles/variables.less';
.row-headTable {
  min-height: 24px;
  background-color: #f8d0c3;
  border-radius: 5px;
  color: @secondary-color;
  font-family: @font-gotham-bold;
  font-size: 12px;
  align-content: center;
  padding: 0 8px;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;