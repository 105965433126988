@import './../../../resources/styles/variables.less';

.user-info-container {
  .info-user {
    display: none;
  }
  @media @mobile {
    .info-user {
      display: block;
      padding-left: @spacing-base;
      text-align: left;
      h5 {
        margin-bottom: 0;
        font-weight: 600;
        color: @primary-color;
      }
      .subtitle {
        color: @primary-color !important;
      }
    }
    .container-image {
      text-align: left;
      width: 100%;
      .ant-space-item {
        padding-bottom: 12.5px !important;
      }
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        border: 1px solid #bab7c6;
        border-radius: 100%;
      }
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;