@import url('./../../../resources/styles/variables.less');

.container-main {
  .ant-space-item {
    padding: @spacing-base !important;
  }
  .header {
    display: flex;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    border-bottom: solid 2px #f5f5f5;
    .title-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .font-bold {
        font-family: @font-gotham-bold;
        font-weight: bold;
      }
      .userInfo {
        display: flex;
        align-items: center;
        .d-block {
          margin-right: 12px;
          display: block;
        }
        .userPosition {
          font-size: 12px;
          color: #ff6e00;
        }
      }
      .icon {
        margin: 18px 0 0 12px;
        cursor: pointer;
      }
    }
    span {
      font-family: @font-gotham-book;
      font-size: 16px;
      color: @secondary-color;
      font-weight: lighter;
      line-height: 20px;
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;