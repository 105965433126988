.divider-finishedLoad {
  margin: 12px 0;
  background-color: #bcbcca;
}
.files-nameFinishedLoad {
  display: flex;
  color: #808495;
  font-size: 12px;
  padding-left: 10px;
  align-items: center;
}
.progressBar-finishedLoad {
  display: flex;
  justify-content: right;
  align-items: center;
}
.column-finishedLoad {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 10px;
  .container-iconFinishedLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }
  .iconExcelDownload {
    width: 36px;
    height: 36px;
    border: none;
    cursor: pointer;
  }
  .container-iconAlert {
    display: flex;
    align-items: center;
    div div svg {
      display: flex;
      align-items: center;
    }
  }
}

.invalid {
  .ant-tooltip-arrow .ant-tooltip-arrow-content {
    background-color: #ffe36e !important;
  }
  .ant-tooltip-inner {
    border-radius: 0px;
    background-color: #ffe36e !important;
    color: #002655;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;