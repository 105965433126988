.hide-close-modal {
  .ant-modal-content {
    .ant-modal-close-x {
      display: none;
    }
  }
}
.mb-1 {
  margin-bottom: 8px;
}
.mr-3 {
  margin-right: 4rem;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.progress-bar {
  .ant-progress-bg {
    background-color: #de6d19;
    height: 3px !important;
  }
  .ant-progress-text {
    color: #de6d19;
    font-size: 9px;
  }
}
.select-component {
  .ant-select {
    .ant-select-selector {
      font-size: 12px;
    }
  }
}
.modal-SendEmail {
  width: 100% !important;
  .ant-modal-content {
    width: 85%;
    max-width: 1182px;
    position: relative;
    margin: 0 auto;
    padding: 0;
    .ant-modal-body {
      padding: 0;
      margin-top: 30px;
    }
  }
}
