@import '../../../resources/styles/variables.less';
.headTable-TrackingModal {
  padding: 0 31px;
  height: 30px;
}
.row-trackingModal {
  font-size: 12px;
  padding: 5px 15px;
  .column-trackingModal {
    display: flex;
    align-items: center;
    color: @secondary-color;
    .icon-trackingModal {
      margin-right: 15px;
    }
    .button-trackingModal {
      cursor: pointer;
    }
  }
}
.divider-trackingModal {
  margin: 0;
  border-top: solid 2px #f5f7fb;
}
.cancel-trackingModal {
  top: -7px;
  right: -5px;
}
.newAction-trackingModal {
  bottom: 10px;
  // top: 87px;
  right: 10px;
  width: 45px;
  height: 45px;
  background-color: #ff6e00;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 36px;
  border-radius: 6px;
  font-family: @font-gotham-light;
  z-index: 1;
}
.cancel-trackingModal,
.newAction-trackingModal {
  cursor: pointer;
  display: flex;
  position: absolute;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;