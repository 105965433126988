.divider-loading {
  margin: 12px 0;
  background-color: #bcbcca;
}
.files-nameLoading {
  display: flex;
  color: #808495;
  font-size: 12px;
  padding-left: 10px;
  align-items: center;
}
.progressBar-Loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;