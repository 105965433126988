@import '../../../resources/styles/variables.less';
.cancel-sendEmailModal {
  display: flex;
  position: relative;
  top: -7px;
  right: -5px;
  justify-content: flex-end;
  cursor: pointer;
}
.container-downloadModal {
  padding: 0 13px 20px 13px;
  border-radius: 10px;
  .title-downloadModal {
    font-size: 20px !important;
    font-family: @font-gotham-bold;
    color: @primary-color;
    margin-bottom: 31px;
  }
  .table-download {
    .ant-table-header {
      display: none;
    }
    .ant-table-body::-webkit-scrollbar {
      width: 8px;
      height: 10px;
    }

    .ant-table-body::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #cbcbcb;
      margin-left: 0.8rem;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
      background: #2b0d61;
      border-radius: 10px;
    }
    .row-download {
      cursor: pointer;
      & > span {
        display: flex;
        gap: 10px;
        align-items: center;
        & > div {
          height: 15px;
        }
      }
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;