@import '../../../resources/styles/variables.less';
.container-SentCommunDetail {
  padding: 0 36px 17px;
  .cancel-sentCommunModal {
    display: flex;
    position: relative;
    top: -7px;
    right: -43px;
    justify-content: flex-end;
    cursor: pointer;
  }
  //////////////HEADER//////////////
  .container-header-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .header-timeEmail {
      font-size: 12px;
      font-family: @font-gotham-bold;
      color: @secondary-color;
      margin-bottom: 8px;
    }
    .header-title {
      font-size: 12.5px;
      color: @secondary-color;
    }
    .header-logo {
      width: 16%;
    }
  }
  .header-divider {
    margin: 0 -19px;
    width: calc(100% + 38px);
    background-color: #bcbcca;
  }
  .header-fileName,
  .isEditable-input-filename {
    margin: 4px 0 !important;
    font-size: 13.5px;
    font-family: @font-gotham-bold;
    color: @secondary-color;
    letter-spacing: normal;
  }
  //////////////BODY//////////////
  .container-email-head {
    margin-bottom: 20px;
  }
  .container-email-body {
    margin: 0px 20px;
  }
  .email-head,
  .isEditable-input-body,
  .isEditable-text-body {
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: @font-gotham-book;
    color: @secondary-color;
    margin-bottom: 3.5px;
  }
  .email-body {
    font-size: 12px;
    align-items: center;
    font-family: @font-gotham-book;
    color: @secondary-color;
    margin-bottom: 3.5px;
    width: 100%;
    p {
      margin: 0;
    }
    table {
      margin: 25px 0;
      line-height: 25px;
      width: 100%;
      text-align: center;
      tr {
        border-bottom: 1px solid;
        border-top: 1px solid;
        line-height: 14px;
      }
    }
  }

  .email-table {
    max-height: 283px;
    overflow-y: hidden;
    margin-bottom: 20px;
  }

  .column-table {
    padding: 4px 10px;
    font-size: 12px;
    background: none;
  }
  .column-table.text-center {
    text-align: center;
  }
  .ant-table {
    background: none;
  }
  .body-divider,
  .isEditable-headEmailDivider,
  .isEditable-body-divider {
    margin: 0 -20px;
    background-color: #bcbcca;
    padding: 0.2px 0;
  }
  //////////////FOOTER//////////////

  .footer-sign,
  .isEditable-footer-sign {
    margin: 22px 0 0 20px;
    width: 300px;
  }

  //////////////EDITABLE//////////////
  .isEditable-container,
  .isEditable-header-fileName,
  .isEditable-container-email-head {
    border: solid 1px #bcbcca;
    background-color: rgba(255, 206, 193, 0.5);
    border-radius: 10px;
  }
  .isEditable-header-fileName {
    margin: 1.5px 0;
    padding: 0 0 0 15px;
    border-radius: 6px;
  }
  .isEditable-container-email-head {
    padding: 7px 0 4px 20px;
    margin: 0 0 7px;
  }
  .isEditable-headEmailDivider {
    margin: 0 -20px;
    width: calc(100% + 20px);
  }
  .isEditable-body-divider {
    margin: 0 -16px;
    width: calc(100% + 10px);
  }
  .isEditable-container-email-body {
    margin: 0 20px;
  }
  .isEditable-footer-sign {
    margin: 7px 0 0 20px;
  }
  .isEditable-input-filename,
  .isEditable-input-body,
  .isEditable-text-body {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
  .isEditable-text-body {
    margin: 15px 0;
    max-height: 100px;
    min-height: 90px;
    height: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #cbcbcb;
      margin-left: 0.8rem;
    }
    &::-webkit-scrollbar-thumb {
      background: #e0937f;
      border-radius: 10px;
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;