@import '../../../resources/styles/variables.less';
.container-dropzone {
  display: flex;
  border: 1px dashed #707070;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  font-size: 14px;
  line-height: 21px;
  color: #160c41;
  height: 268px;
  .text-upload {
    font-size: 12px;
    margin: 0 73px 0 12px;
    color: #808495;
  }
  .bn-upload {
    height: 35px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ff6e00;
    color: #ffffff;
    font-size: 15px;
    font-family: @font-gotham-light;
  }
}
.smallDropzone {
  height: 150px;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;