.content-loadingFiles {
  .container-btn {
    display: flex;
    justify-content: flex-end;

  }

  .bn-analysis  {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #838296 ;
    color: #ffff;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .bn-analysis[disabled] , .bn-analysis[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .opacity {
    pointer-events: none;
    opacity: 0.3;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;