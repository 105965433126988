@import '../../../resources/styles/variables.less';
.container-head-titlePage {
  display: flex;
  justify-content: space-between;
  .title-titlePage {
    margin-bottom: 0 !important;
    color: @primary-color;
    font-family: @font-gotham-medium;
  }
  .secondSection-titlePage {
    display: flex;
    align-items: center;
    .icon-secondSection {
      margin-right: 8px;
      cursor: pointer;
    }
    .title-secondSection {
      margin: 0 0 0 10px;
      font-size: 18px;
      font-style: normal;
      color: @secondary-color;
      font-family: @font-gotham-medium;
    }
  }
}
.divider-titlePage {
  margin: 0;
  border: solid 1px #e9e9f0;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;