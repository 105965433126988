@import '../../../resources/styles/variables.less';
.cancel-sendEmailModal {
  display: flex;
  position: relative;
  top: -7px;
  right: -5px;
  justify-content: flex-end;
  cursor: pointer;
}
.container-sendEmailModal {
  padding: 0 11px 141px 23px;
  border-radius: 10px;
  .title-sendEmailModal {
    font-size: 20px !important;
    font-family: @font-gotham-bold;
    color: @primary-color;
    margin-bottom: 31px;
  }
  .row-contentEmail {
    font-size: 12px;
    // height: 49px;
    background-color: #f5f7fb;
    padding: 0 10px;
    margin-top: 2px;
    border-radius: 16px;
    border: solid 0.1px #bcbcca;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%);
    .column-contentEmail {
      display: flex;
      align-items: center;
      min-height: 49px;
      padding: 4px;
    }
    .column-select {
      .ant-select-selector {
        background-color: #f5f7fb;
        border: none;
      }
      .ant-select-arrow {
        color: #092e5b;
      }
    }

    .container-actionsEmail {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .icon-actionEmail {
        height: 30px;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
        background: white;
      }
    }
  }
}
.opacity {
  pointer-events: none;
  opacity: 0.3;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;