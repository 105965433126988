@import '../../../resources/styles/variables.less';
.container-loginPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  place-items: center;
  height: 100vh;
  .firstSection-login {
    padding: 70px 80px 70px 100px;
    width: 100%;
    .container-title-login {
      display: flex;
      margin: 64px 0 24px;
    }
    .title-login {
      margin: 0 16px 0 0;
      color: @secondary-color;
      letter-spacing: normal;
    }
    .platform {
      font-family: @font-gotham-light;
      font-size: 24px;
    }
    .sucavec {
      font-size: 26px;
      font-family: @font-gotham-bold;
    }
    .subtitle-login,
    .subtitle-login-error {
      font-family: @font-gotham-book;
      margin-bottom: 104px;
      color: #808495;
      font-size: 20px;
    }
    .subtitle-login-error {
      margin-bottom: 40px;
    }
    .container-alertMessage {
      display: flex;
      align-items: baseline;
      margin: 0 0 40px;
      padding: 6.9px 9px 7.1px 16px;
      background-color: #ffcec1;
      .text-alertMessage {
        font-size: 14px;
        margin: 0 0 0 16px;
        color: #35394e;
        font-family: @font-gotham-book;
      }
    }
    .warning {
      color: #ff0000;
    }

    .input-login {
      width: 100%;
      position: relative;

      input {
        width: 100%;
        max-width: 560px;
        background: #ffffff;
        margin: 0 0 16px;
        padding: 23px 25px 12px;
        color: @primary-color;
        font-size: 16px;
        font-family: @font-gotham-medium; //to change for medium font
        border: solid 1px #c1bbbb;
        &.activeInput {
          color: 'green';
        }
        &.input-warning {
          border-left: solid 5px #ff0000;
        }
      }

      .label-login {
        position: absolute;
        transition: 0.2s ease all;
        color: @secondary-color;
        top: 3px;
        left: 27px;
        font-size: 14px;
        font-family: @font-poppins-light;
      }
    }
    /*
    .input-login {
      width: 100%;
      position: relative;
      input {
        width: 560px;
        background: #ffffff;
        margin: 0 0 16px;
        padding: 23px 25px 12px;
         color: @primary-color;
        font-size: 16px;
        font-family: @font-gotham-medium; //to change for medium font
        border: solid 1px #c1bbbb;

        &:focus ~ .label-login,
        &:not(:focus):valid ~ .label-login {
          top: 3px;
          left: 27px;
          font-size: 13px;
          color: #808495;
          opacity: 1;
          font-family: @font-poppins-light;
        }

        &:focus {
          border-left: 4px solid @primary-color;
        }
      }
      input[type='text']:focus {
        outline: none;
      }
      input[type='password']:focus {
        outline: none;
      }
      .label-login {
        position: absolute;
        // pointer-events: none;
        left: 27px;
        top: 23px;
        transition: 0.2s ease all;
       color: @secondary-color;
        font-size: 16px;
        font-family: @font-gotham-medium;
      }
    }
    */
    .selected-input {
      border-left: solid 3px #002655;
    }
    .container-passwordLogin {
      font-size: 16px;
      font-family: @font-gotham-book;
      display: flex;
      justify-content: space-between;
      margin: 23px 0 72px 0;
      color: @secondary-color;
      line-height: 1.88;
    }
    .text-underline {
      text-decoration: underline;
      cursor: pointer;
    }
    .text-bold {
      font-weight: bold;
    }

    .f-size {
      font-size: 16px;
    }
    .f-direction {
      flex-direction: column;
      padding: 12px 25px;
    }
    .bn-login {
      margin-right: 40px;
      border-radius: 0;
      border: solid 1px @primary-color;
      background-color: @primary-color;
      width: 128px;
      height: 53px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      color: #ffffff;
      font-family: @font-gotham-book;
      font-size: 16px;
      cursor: pointer;
    }
    .bn-createAccount {
      background-color: #ffffff;
      color: @primary-color;
    }
  }
  .secondSection-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 29px;
    background-color: rgba(229, 229, 229, 0.41);
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      max-width: 600px;
      margin: 30px;
    }
  }
}

@media screen and (max-width: 968px) {
  .container-loginPage {
    grid-template-columns: 1fr;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;