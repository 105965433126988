.content-validatingFiles {
  .container-btn {
    display: flex;
    justify-content: flex-end;;
  }

  .bn-analysis {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #838296;
    color: #ffff;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
}

span.date {
  color: #002655;
  font-size: 14px;
}

.btn-update{
  background: #de6d19;
  color: #ffff;
}


@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;