@import '../../../resources//styles/variables.less';
.cancel-SentCommun {
  display: flex;
  position: relative;
  top: -7px;
  right: -5px;
  justify-content: flex-end;
  cursor: pointer;
}
.container-SentCommun {
  padding: 0 15px 145px 20px;
  border-radius: 10px;
  .title-SentCommun {
    font-size: 20px !important;
    font-family: @font-gotham-bold;
    color: @primary-color;
    margin-bottom: 31px;
  }
  .row-contentSentCommun {
    font-size: 12px;
    height: 49px;
    .column-contentSentCommun {
      display: flex;
      align-items: center;
      height: 49px;
      padding: 0 0 7px 8px;
    }
    .container-actionsSentCommun {
      display: flex;
      .icon-actionsSentCommun {
        margin: 0 20px 0 8px;
      }
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;