@import '../../../resources/styles/variables.less';
.container-uploadNotification {
  display: flex;
  justify-content: space-between;
  width: 400px;
  padding: 0 0 0 13px;
  border-radius: 1.5px;
  align-items: flex-start;
  .icon-uploadNotification {
    width: 30px;
    margin: auto 0;
  }
  .icon-spinner {
    margin: 8px 0 0 17px;
  }
  .rotate {
    animation: rotating 1.2s linear infinite;
  }
  .text-uploadNotification {
    text-align: center;
    justify-items: center;
    align-items: center;
    font-size: 14px;
    font-family: @font-gotham-book;
    margin: 0;
    width: 80%;
    line-height: 1.14;
    margin: 9px 0;
    color: #343950;
  }
  .ic-close {
    font-family: @font-gotham-bold;
    font-size: 32px;
    cursor: pointer;
    margin: -5px 13px 0 0;
  }
}
.type-one,
.type-three {
  background-color: #f8eec3;
  border-bottom: solid 3px #ee762f;
}
.type-two {
  background-color: #def8eb;
  border-bottom: solid 3px #4ad991;
}

@keyframes rotating {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;