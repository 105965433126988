@import '../../../resources/styles/variables.less';
.container-loadingLogin {
  display: flex;
  padding: 72px 28.5px 125.5px 80px;
  background-color: rgba(229, 229, 229, 0.41);
  justify-content: space-between;
  .firsSection-loadingLogin {
    width: 40%;
    .logo-loadingLogin {
      width: 55%;
    }
    .container-title-login {
      display: flex;
      margin: 95px 0 111px;
    }
    .title-loadingLogin {
      margin: 0 16px 0 0;
      color: @secondary-color;
      letter-spacing: normal;
    }
    .platform-loadingLogin {
      font-family: @font-gotham-light;
      font-size: 35px;
      line-height: 1.26;
    }
    .sucavec-loadingLogin {
      font-family: @font-gotham-bold;
      font-size: 34px;
      line-height: 1.29;
      margin-top: 0 !important;
    }
    .icon-loadingLogin {
      margin: 0 0 105px 140px;
    }
  }
  .secondSection-loadingLogin {
    display: flex;
    justify-content: center;
    width: 60%;
    .img-loadingLogin {
      margin-top: 160px;
      width: 85%;
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;