@import './../../../resources/styles/variables.less';
.ant-menu {
  margin-top: 0;
  background-color: #f5f5f5;
  @media @mobile {
    border-top: 1px solid #f3f3f3;
    .menu-logout {
      border-top: 1px solid #f3f3f3;
    }
  }
}
li.ant-menu-item {
  height: 35px !important;
}
.title-menu {
  display: flex;
  font-size: 11px;
  color: #ff6e00;
  margin: 25px 0 15px 24px;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;