@import '../../../resources/styles/variables.less';
.container-successModal {
  padding: 0 50px;
  .container-icon-successModal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title-successModal {
    font-size: 20px;
    text-align: center;
    color: @primary-color !important;
    font-family: @font-gotham-medium;
    margin-bottom: 13px;
  }
  .subtitle-successModal {
    font-family: @font-gotham-book;
    font-size: 16px;
    color: @primary-color;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 24px;
  }
  .container-bn-successModal {
    display: flex;
    justify-content: center;
    .bn-successModal {
      font-size: 16px;
      font-family: @font-gotham-book;
    }
    .bn-successModal-twoBottoms {
      width: 116.1px;
      height: 44.8px;
      margin-right: 124px;
      border-radius: 7px;
      box-shadow: 0 8px 15px 0 rgba(0, 78, 175, 0.16);
    }
    .bn-successModal-no {
      margin: 0;
      border: solid 1px #c13632;
    }
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;