.container-table {
  margin-top: 20px;
}
.file-nameAnalysisResult {
  display: flex;
  color: #808495;
  padding-left: 10px;
  align-items: center;
  font-size: 12px;
}
.colSelect-analysisResult {
  padding: 0 72px 0 0;

  .ant-select-selector {
    background-color: transparent;
    border: none;
    background: none;
  }
  .ant-select-arrow {
    color: #092e5b;
  }
}
.column-validation {
  display: flex;
  align-items: center;
  // justify-content: center;
  .validation-analysisResult {
    display: flex;
    align-items: center;
    .margin-validation {
      margin: 0 13px 0 0;
    }
  }
}

.ant-tooltip-inner {
  border-radius: 0px;
  background-color: #838296 !important;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;