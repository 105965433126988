@import '../../../resources/styles/variables.less';

.title-tracking {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 26px;
  .secondTitle-tracking {
    font-size: 18px;
    font-family: @font-gotham-medium;
    color: @secondary-color;
    margin: 0;
  }
  .datePicker-tracking {
    width: 180px;
    border: none;
    border-bottom: solid 1px #7f828a;
    border-radius: 0;
  }
}

.bold-tracking {
  font-family: @font-gotham-bold;
  color: @secondary-color;
}
.table-tracking {
  .ant-table-thead > tr > th {
    font-size: 12px;
    color: @secondary-color;
    font-family: @font-gotham-bold;
    background-color: #f8d0c3;
    align-content: center;
    padding: 4px 8px !important;
    &:first-child {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    &:last-child {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
  .ant-table-row {
    font-size: 12px;
    border: solid 0.1px #bcbcca;
    margin: 8px 0 0;
    border-radius: 20px;
    padding: 8px 24px;
    font-family: @font-gotham-book;
    color: @secondary-color;
    &:nth-child(even) {
      background: #f5f7fb;
      border: none;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    }
    & > td {
      padding: 5px 16px !important;
    }
  }

  .column-actionsTracking {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .icon-fileTracking {
    padding: 0 6px;
    background: #ffa001;
    border-radius: 8px;
    width: 25px;
    height: 25px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-actionTracking {
    height: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    background: white;
  }
  .icon-disabled {
    background: #c5c5c5;
    pointer-events: none;
    border-radius: 5px;
  }
  .column-validation {
    display: flex;
    align-items: center;
    gap: 20px;
    & > div {
      display: flex;
      gap: 5px;
    }
  }
  .hide-button {
    visibility: hidden;
  }
  .ant-pagination {
    justify-content: center;
    margin-top: 20px;
    & > li {
      border-radius: 5px;
      min-width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
    }
    .ant-pagination-item.ant-pagination-item-active {
      background: #807ddb;
      border: 1px solid #807ddb;
      a {
        color: #fff;
      }
    }
  }

  .ant-pagination-options {
    display: none;
  }
}
.container-footer-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.container-bn-foot {
  display: flex;
  justify-content: flex-end;
  .bn-footer {
    width: 188px;
    height: 45px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #838296;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;