@import '../../../resources/styles/variables.less';
.uploadNotification {
  position: fixed;
  right: 50px;
  top: 55px;
  z-index: 1500;
}
.subtitle-home {
  font-family: @font-gotham-medium;
  font-size: 12px;
  margin: 16px 0 32px;
  color: #808495;
}
.container-bn-footer {
  margin-top: 152px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .bn-analysis {
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(131, 130, 150, 0.12);
    color: #ffff;
    font-size: 18px;
  }
  .text-footer {
    font-size: 14px;
    color: rgba(128, 132, 149, 0.28);
  }
  .link-delete {
    font-size: 16px;
    text-decoration: underline;
    color: rgba(255, 110, 0, 0.28);
    cursor: pointer;
  }
}

.opacity {
  pointer-events: none;
  opacity: 0.3;
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;