@import '../../../resources/styles/variables.less';
.cancel-newActionModal {
  display: flex;
  position: relative;
  top: -7px;
  right: -5px;
  justify-content: flex-end;
  cursor: pointer;
}
.container-newActionModal {
  padding: 0 15px 27px 20px;
  border-radius: 10px;
  .title-newActionModal {
    font-size: 20px !important;
    font-family: @font-gotham-bold;
    color: @primary-color;
    margin-bottom: 31px;
  }
  .row-contentNewAction {
    font-size: 12px;
    height: 49px;
    & > div {
      padding-top: 5px;
    }
    .ant-select-selector {
      height: 37px;
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 37px;
      }
    }
    .column-contentNewAction {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // align-items: center;
      // height: 49px;
      // padding: 0 0 4px 15px;
      .ic-attachment {
        margin: 8px 0 0 8px;
        padding: 0;
      }
      .ant-layout {
        justify-content: center;
      }
    }

    .comments-contentNewAction {
      height: 49px;
      // padding: 12px 0 0;
      .input-comments {
        border: none;
        height: 14px;
        resize: none;
        line-height: 1.1;
        font-size: 13px;
        padding: 7px;
      }
      .divider-contentNewAction {
        margin: 0;
        border: 1px solid #7f828a;
      }
    }
    .error-message {
      color: red;
      align-content: flex-start;
      align-self: flex-start;
      font-size: 10px;
    }
  }

  .container-bn-newActionModal {
    display: flex;
    justify-content: flex-end;
    margin-top: 76px;
    padding-right: 20px;
    .bn-newActionModal {
      width: 183.1px;
      height: 44.8px;
      border-radius: 7px;
      box-shadow: 0 8px 15px 0 rgba(0, 78, 175, 0.16);
    }
  }
}

.image-upload > input {
  display: none;
}
.image-upload > label {
  display: flex;
  font-size: 13px;
  gap: 5px;
}
.image-upload svg {
  width: 10px;
  cursor: pointer;
}

.container-file {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: 'red';
  .row-file {
    border: 1px solid #f8d0c3;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 1px 0;
    display: flex;
    .remove-file {
      cursor: pointer;
    }
  }

  label {
    margin-left: 10px;
    font-size: 14px;
  }
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;