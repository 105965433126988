@font-face {
  font-family: 'Poppins-Light';
  src: url('../fonts/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('../fonts/GothamBold.ttf');
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('../fonts/GothamLight.ttf');
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('../fonts/GothamMedium.ttf');
}
@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/GothamBook.ttf');
}

@primary-color: #2b0d61;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;